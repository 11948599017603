

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"customTheme","themes":{"customTheme":{"dark":false,"colors":{"themeBlack":"#050505","themeRed":"#CB5546","themeGreen":"#33A28F","themeYellow":"#F1D76A","themeGreenDark":"#247775","themeGreenLight":"#E8F7F4","themeRedDark":"#C94B3B"}}}}}')
  
  
  
  
  return options
}

